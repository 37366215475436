import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

function CuisineDescription(props) {
    return (
        <>
            <div className="about-wrapper ptb--120">
                <div className="container">
                    <div className="row align-items-center text-center text-lg-left">
                        <div className="col-lg-6 col-md-12">
                            <div className="">
                                <div className="section-title">
                                    <h2 className="title">Sitarjevec snack and Dwarf “šmorn”, Dwarf shredded pancake</h2>
                                    <p className="description">
                                        By prior arrangement (at least 10 people), visitors can try the traditional "Sitarjevec snack" or "dwarf šmorn", Dwarf shredded pancake when visiting the Export Pit. Sitarjevec Snack consists of a cake with cracklings, bacon in buckwheat dough, horseradish mousse on apple crumbles and compote from seasonal fruit.
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-xl-6 mt-4">
                            <div className="thumbnail">
                                <StaticImage
                                    src="../../../assets/images/rudnik/sitarjevska-malca.jpg"
                                    alt="Sitarjevec snack and Dwarf, shredded pancake"
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CuisineDescription;