import React from 'react';
import SEO from "../../../components/en/seo/Seo";

import Header from "../../../components/en/headers/Header";
import Footer from "../../../components/en/layouts/Footer";
import CallToAction from "../../../components/en/layouts/CallToAction";
import CuisineHeader from "../../../components/en/cousine/CousineHeader";
import CuisineDescription from "../../../components/en/cousine/CousineDescription";
import CuisineGoodToKnow from "../../../components/en/cousine/CuisineGoodToKnow";

function Culinary(props) {
    return (
        <div>
            <SEO title="Culinary"
                 description="By prior arrangement (at least 10 people), visitors can try the traditional Sitarjevec snack, Dwarf shredded pancake when visiting the Export Pit. SITARJEVEC SNACK consists of a cake with cracklings, bacon in buckwheat dough, horseradish mousse on apple crumbles and compote from seasonal fruit."
            />
            <Header/>
            <CuisineHeader/>
            <CuisineDescription/>
            <CuisineGoodToKnow/>
            <CallToAction/>
            <Footer/>
        </div>
    );
}

export default Culinary;