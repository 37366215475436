import React from 'react';
import { FiCheck } from "react-icons/fi";
import { Link } from 'gatsby';
import {StaticImage} from "gatsby-plugin-image";

function CuisineGoodToKnow(props) {
    return (
        <div className="rn-about-area ptb--120 bg_color--3 text-center text-lg-left">
            <div className="container">
                <div className="row row--35 align-items-center">
                    <div className="col-lg-6 order-lg-1 order-0">
                        <div className="">
                            <div className="section-title">
                                <h2 className="title">It is good to know</h2>
                            </div>
                            <div className="mt--30">
                                <ul className="list-style--1">
                                    <li>
                                        <FiCheck />
                                        Visiting the mine with lunch is possible only with prior notice - <Link to={"/en/contact-and-information"}>reservation</Link>
                                    </li>

                                    <li>
                                        <FiCheck />
                                        The snack is also adapted for vegetarians and vegans.
                                    </li>

                                    <li>
                                        <FiCheck />
                                        A tour of the mine with a snack takes an hour and a half.
                                    </li>
                                    <li>
                                        <FiCheck />
                                        The tunnel has a constant temperature of 10 °C and 100 % humidity.
                                    </li>
                                </ul>
                            </div>

                            <div className="mt--40">
                                <Link to={"/en/contact-and-information"} className="btn-default size-md">Book a Tour</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default CuisineGoodToKnow;